@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLXSPJVVvS7pivFi9a_v_830O_kPjaewOT7xyLjus2-9PB6GDs-01xiojPuFmmAe3kg);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

::marker {
  color: white;
}
.otp input {
  width: 4.5rem !important;
  height: 3rem !important;
  color: #000 !important;
}
li.headerDrop {
  border: 1px solid #2243CE !important;
  padding: 10px;
  box-shadow: 1px 2px #888888;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  text-align: right;
  height: 30px;
}
.navHeaderRight {
  height: 45px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin-right: 50px;
}
.navbarHeaderRight {
  margin-right: 20px;
}
.signUp {
  margin-top: 10px;
  text-align: center;
}
.signupPage-wrapper {
  margin: 0 auto;
  max-width: 980px;
  margin-top: 20px;
}
.signupPage-wrapper-full .logo-wrapper{
  margin-top: 20px;
}
.signupPage{
  padding: 20px 20px 20px 20px !important;
  background: rgba(255,255,255, 1);
  z-index: 3;
}
/* .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #03d665;
} */
/* @media screen and (min-width: 992px) {
  .signupPage-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
} */
/* .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #03d665 !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: #03d665 !important;
} */
.loginBack {
  font-size: 14px;
  margin-bottom: 14px;
  text-align: right;
}
.loginBack a{
  color: #169933 !important;
  font-weight: bold;
}
.loginBack a:hover{
  text-decoration: underline;
}
.__profile{
  background: linear-gradient(to right, #ee5a6f, #f29263);
}
.__orderForm {
  padding: 0px 100px 0px 100px;
}
.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture {
  margin-top: 0px;
}
.billGen {
  padding: 0px 10%;
}
.ant-table-content {
  overflow-y: auto !important;
}

.ant-input-number-input-wrap {
  height: 40px;
}
/* .ant-col.ant-form-item-control .ant-input-number {
  border: 1px solid #03D665 !important;
} */


/* ////// */

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

.tableDropDown {
  /* width: 100%; */
  height: 30px;
  border: 1px solid #03D665;
}
.tableDropDown:focus {
  outline:none;
}
::-webkit-input-placeholder {
  text-transform: capitalize;
}
:-ms-input-placeholder {
  text-transform: capitalize;
}
::-ms-input-placeholder {
  text-transform: capitalize;
}
::placeholder {
  text-transform: capitalize;
}

.ant-select-selection-placeholder {
  text-transform: capitalize;
}

.ant-pagination-options .ant-select .ant-select-selector, .ant-pagination-options .ant-select-selector {
  height: auto !important;
  border-color:#d9d9d9 !important;
}

/* .editable-row .ant-table-cell-row-hover .editable-cell-value-wrap{
border: 1px solid #d9d9d9;
padding: 5px;
cursor: pointer;
} */
.c23 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: middle;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #19AAF8;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 153pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c26 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #19AAF8;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 153pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c29 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  background-color: #f3f3f3;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 225pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c87 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 76.5pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c27 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 238.5pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c80 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 72pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c61 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: middle;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 108pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.print-source {
  display: none;
  /* background-color: red; */
}

@media print {
  .print-source {
    display: block;
    /* background-color: green; */
  }
}

.c75 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 215.2pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c85 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 476.2pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c64 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 72pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c65 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 327pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c74 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: middle;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 76.5pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c3 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 153pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c78 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: middle;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 225pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c38 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 0pt;
  width: 544.5pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c68 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 226.5pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c33 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 215.2pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c56 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 108pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c83 {
  border-right-style: solid;
  padding: 0pt 5.8pt 0pt 5.8pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 76.5pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c63 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 309.8pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c51 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #ffffff;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 72pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c44 {
  border-right-style: solid;
  padding: 9pt 9pt 9pt 9pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #ffffff;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 225pt;
  border-top-color: #000000;
  border-bottom-style: solid
}

.c7 {
  margin-left: 252pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt
}

.c37 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 12pt
}

.c14 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  /* font-family: "Arial"; */
  font-style: normal
}

.c10 {
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: "Trebuchet MS";
  font-style: normal
}

.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Trebuchet MS";
  font-style: normal
}

.c6 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt
}

.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Trebuchet MS";
  font-style: normal
}

.c22 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Trebuchet MS";
  font-style: normal
}

.c32 {
  color: #de478e;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: "Trebuchet MS";
  font-style: normal
}

.c13 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 12pt
}

.c15 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal
}

.c0 {
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 15pt;
  font-family: "Trebuchet MS";
  font-style: normal
}

.c19 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  width: 700px;
  text-align: left
}

.c5 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: right
}

.c52 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: center
}

.c76 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: right
}

.c24 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left
}

.c39 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  text-align: left;
  height: 12pt
}

.c35 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  vertical-align: baseline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  font-style: normal
}

.c59 {
  -webkit-text-decoration-skip: none;
  color: #19AAF8;
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  font-size: 9pt
}

.c54 {
  margin-left: 238.5pt;
  border-spacing: 0;
  border-collapse: collapse;
  width: 375px;
  margin-right: auto
}

.c17 {
  margin-left: -5.8pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
  width: 700px;
}

.c62 {
  font-size: 10pt;
  font-family: "Calibri";
  font-style: italic;
  font-weight: 400
}

.c47 {
  color: #19AAF8;
  font-weight: 700;
  font-size: 16pt;
  font-family: "Trebuchet MS"
}

.c11 {
  font-size: 30pt;
  font-family: "Trebuchet MS";
  color: #ffffff;
  font-weight: 400
}

.c16 {
  font-size: 10pt;
  font-family: "Trebuchet MS";
  color: #000000;
  font-weight: 700
}

.c66 {
  margin-left: -6.5pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
  width: 703px;
}

.c12 {
  font-size: 11pt;
  font-family: "Trebuchet MS";
  color: #ffffff;
  font-weight: 700
}

.c53 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-style: italic
}

.c18 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500000000000001;
  text-align: left
}

.c50 {
  margin-left: -8.2pt;
  border-spacing: 0;
  border-collapse: collapse;
  width: 703px;
  margin-right: auto
}

.c71 {
  font-weight: 400;
  font-size: 9pt;
  font-family: "Times New Roman"
}

.c21 {
  font-size: 14pt;
  font-family: "Trebuchet MS";
  font-weight: 400
}

.c49 {
  font-weight: 400;
  font-size: 12pt;
  font-family: "Calibri"
}

.c20 {
  font-size: 10pt;
  font-family: "Trebuchet MS";
  font-weight: 400;
  margin-left: -10px;
  margin-right: 10px;
}

.c58 {
  font-size: 14pt;
  font-family: "Trebuchet MS";
  font-weight: 700;
  margin-left: -10px;
}

.c67 {
  font-weight: 400;
  font-size: 12pt;
  font-family: "Gill Sans"
}

.c72 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none
}

.c25 {
  border-bottom-color: #dfdbd5;
  border-bottom-width: 0.8pt;
  border-bottom-style: solid;
  width: 700px;
}

.c60 {
  background-color: #ffffff;
  max-width: 527.8pt;
  padding: 36pt 36pt 36pt 31.5pt
}

.c34 {
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal
}

.c55 {
  font-size: 13pt;
  font-family: "Trebuchet MS";
  font-weight: 700
}

.c86 {
  font-size: 10pt;
  font-style: italic
}

.c9 {
  font-weight: 700;
  font-family: "Calibri"
}

.c84 {
  font-weight: 400;
  font-family: "Calibri"
}

.c43 {
  color: inherit;
  text-decoration: inherit
}

.c45 {
  color: #818181;
  font-size: 9pt
}

.c79 {
  margin-left: 216pt;
  text-indent: 36pt
}

.c28 {
  font-weight: 400;
  font-family: "Trebuchet MS"
}

.c77 {
  height: 39.1pt
}

.c57 {
  height: 19.5pt
}

.c69 {
  color: #ffffff
}

.c41 {
  font-size: 11pt
}

.c36 {
  height: 31pt
}

.c88 {
  margin-left: 252pt
}

.c46 {
  height: 12pt
}

.c42 {
  height: 12.6pt
}

.c8 {
  margin-right: 8.2pt
}

.c73 {
  background-color: #ffffff
}

.c40 {
  height: 69pt
}

.c30 {
  color: #000000
}

.c82 {
  height: 1pt
}

.c70 {
  font-size: 16pt
}

.c31 {
  margin-right: 8.5pt
}

.c81 {
  font-size: 15pt
}

.c48 {
  background-color: #19AAF8
}

.c4 {
  height: 0pt
}

.c89 {
  background-color: #f3f3f3
}

.c90 {
  height: 30pt
}

p[name="goHTML"] {
  margin: 0;
  color: #000000;
  font-size: 12pt;
  /* font-family: "Gill Sans" */
}

/* Scrollbar Styling */
#mySider::-webkit-scrollbar  {
  width: 5px;
}

#mySider::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border-radius: 5px;
}

#mySider::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #ffffff; 
}
.ant-select-selector {
  border: 1px solid #19AAF8 !important; }

  #tbl th {
    text-align: left;
  }


  /* Scrollbar Styling */
.dynamicSMS::-webkit-scrollbar  {
  height: 5px;
  width: 5px;
}

.dynamicSMS::-webkit-scrollbar-track {
  background-color: #d6dddb;
  border-radius: 5px;
}

.dynamicSMS::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #19AAF8; 
}
/*--------------------------------------------------------------
>> TABLE OF CONTENTS:
----------------------------------------------------------------
1. Normalize
2. Typography
3. Invoice General Style
--------------------------------------------------------------*/
/*--------------------------------------------------------------
2. Normalize
----------------------------------------------------------------*/


/*--------------------------------------------------------------
2. Typography
----------------------------------------------------------------*/

#billinvoice {
  color: #777777;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  overflow-x: hidden;
 
}


.billinvoice img {
  border: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}



.billinvoice table {
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
}

.billinvoice th {
  text-align: left;
}

.billinvoice td {
  border-top: 1px solid #eaeaea;
}

.billinvoice th {
    padding: 10px 15px;
    line-height: 1.55em;
  }
.billinvoice td {
    padding: 10px 15px;
    line-height: 1.55em;
  }

/*--------------------------------------------------------------
3. Invoice General Style
----------------------------------------------------------------*/
.cs-f10 {
  font-size: 10px;
}

.cs-f11 {
  font-size: 11px;
}

.cs-f12 {
  font-size: 12px;
}

.cs-f13 {
  font-size: 13px;
}

.cs-f14 {
  font-size: 14px;
}

.cs-f15 {
  font-size: 15px;
}

.cs-f16 {
  font-size: 16px;
}

.cs-f17 {
  font-size: 17px;
}

.cs-f18 {
  font-size: 18px;
}

.cs-f19 {
  font-size: 19px;
}

.cs-f20 {
  font-size: 20px;
}

.cs-f21 {
  font-size: 21px;
}

.cs-f22 {
  font-size: 22px;
}

.cs-f23 {
  font-size: 23px;
}

.cs-f24 {
  font-size: 24px;
}

.cs-f25 {
  font-size: 25px;
}

.cs-f26 {
  font-size: 26px;
}

.cs-f27 {
  font-size: 27px;
}

.cs-f28 {
  font-size: 28px;
}

.cs-f29 {
  font-size: 29px;
}

.cs-light {
  font-weight: 300;
}

.cs-normal {
  font-weight: 400;
}

.cs-medium {
  font-weight: 500;
}

.cs-semi_bold {
  font-weight: 600;
}

.cs-bold {
  font-weight: 700;
}

.cs-m0 {
  margin: 0px;
}

.cs-mb0 {
  margin-bottom: 0px;
}

.cs-mb1 {
  margin-bottom: 1px;
}

.cs-mb2 {
  margin-bottom: 2px;
}

.cs-mb3 {
  margin-bottom: 3px;
}

.cs-mb4 {
  margin-bottom: 4px;
}

.cs-mb5 {
  margin-bottom: 5px;
}

.cs-mb6 {
  margin-bottom: 6px;
}

.cs-mb7 {
  margin-bottom: 7px;
}

.cs-mb8 {
  margin-bottom: 8px;
}

.cs-mb9 {
  margin-bottom: 9px;
}

.cs-mb10 {
  margin-bottom: 10px;
}

.cs-mb11 {
  margin-bottom: 11px;
}

.cs-mb12 {
  margin-bottom: 12px;
}

.cs-mb13 {
  margin-bottom: 13px;
}

.cs-mb14 {
  margin-bottom: 14px;
}

.cs-mb15 {
  margin-bottom: 15px;
}

.cs-mb16 {
  margin-bottom: 16px;
}

.cs-mb17 {
  margin-bottom: 17px;
}

.cs-mb18 {
  margin-bottom: 18px;
}

.cs-mb19 {
  margin-bottom: 19px;
}

.cs-mb20 {
  margin-bottom: 20px;
}

.cs-mb21 {
  margin-bottom: 21px;
}

.cs-mb22 {
  margin-bottom: 22px;
}

.cs-mb23 {
  margin-bottom: 23px;
}

.cs-mb24 {
  margin-bottom: 24px;
}

.cs-mb25 {
  margin-bottom: 25px;
}

.cs-mb26 {
  margin-bottom: 26px;
}

.cs-mb27 {
  margin-bottom: 27px;
}

.cs-mb28 {
  margin-bottom: 28px;
}

.cs-mb29 {
  margin-bottom: 29px;
}

.cs-mb30 {
  margin-bottom: 30px;
}

.cs-pt25 {
  padding-top: 25px;
}

.cs-width_1 {
  width: 8.33333333%;
}

.cs-width_2 {
  width: 16.66666667%;
}

.cs-width_3 {
  width: 25%;
}

.cs-width_4 {
  width: 33.33333333%;
}

.cs-width_5 {
  width: 41.66666667%;
}

.cs-width_6 {
  width: 50%;
}

.cs-width_7 {
  width: 58.33333333%;
}

.cs-width_8 {
  width: 66.66666667%;
}

.cs-width_9 {
  width: 75%;
}

.cs-width_10 {
  width: 83.33333333%;
}

.cs-width_11 {
  width: 91.66666667%;
}

.cs-width_12 {
  width: 100%;
}

.cs-accent_color,
.cs-accent_color_hover:hover {
  color: #19AAF8;
}

.cs-accent_bg,
.cs-accent_bg_hover:hover {
  background-color: #19AAF8;
}

.cs-primary_color {
  color: #111111;
}

.cs-secondary_color {
  color: #777777;
}

.cs-ternary_color {
  color: #353535;
}

.cs-ternary_color {
  border-color: #eaeaea;
}

.cs-focus_bg {
  background: #f6f6f6;
}

.cs-accent_10_bg {
  background-color: rgba(42, 209, 157, 0.1);
}

.cs-container {
  max-width: 880px;
  padding: 30px 15px;
  margin-left: auto;
  margin-right: auto;
}

.cs-text_center {
  text-align: center;
}

.cs-text_right {
  text-align: right;
}

.cs-border_bottom_0 {
  border-bottom: 0;
}

.cs-border_top_0 {
  border-top: 0;
}

.cs-border_bottom {
  border-bottom: 1px solid #eaeaea;
}

.cs-border_top {
  border-top: 1px solid #eaeaea;
}

.cs-border_left {
  border-left: 1px solid #eaeaea;
}

.cs-border_right {
  border-right: 1px solid #eaeaea;
}

.cs-table_baseline {
  vertical-align: baseline;
}

.cs-round_border {
  border: 1px solid #eaeaea;
  overflow: hidden;
  border-radius: 6px;
}

.cs-border_none {
  border: none;
}

.cs-border_left_none {
  border-left-width: 0;
}

.cs-border_right_none {
  border-right-width: 0;
}

.cs-invoice.cs-style1 {
  background: #fff;
  border-radius: 10px;
  padding: 50px;
}

.cs-invoice.cs-style1 .cs-invoice_head {
  display: flex;
  justify-content: space-between;
}

.cs-invoice.cs-style1 .cs-invoice_head.cs-type1 {
  align-items: flex-end;
  padding-bottom: 25px;
  border-bottom: 1px solid #eaeaea;
}

.cs-invoice.cs-style1 .cs-invoice_footer {
  display: flex;
}

.cs-invoice.cs-style1 .cs-invoice_footer table {
  margin-top: -1px;
}

.cs-invoice.cs-style1 .cs-left_footer {
  width: 55%;
  padding: 10px 15px;
}

.cs-invoice.cs-style1 .cs-right_footer {
  width: 46%;
}

.cs-invoice.cs-style1 .cs-note {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
}

.cs-invoice.cs-style1 .cs-note_left {
  margin-right: 10px;
  margin-top: 6px;
  margin-left: -5px;
  display: flex;
}

.cs-invoice.cs-style1 .cs-note_left svg {
  width: 32px;
}

.cs-invoice.cs-style1 .cs-invoice_left {
  max-width: 55%;
}

.cs-invoice_btns {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.cs-invoice_btns .cs-invoice_btn:first-child {
  border-radius: 5px 0 0 5px;
}

.cs-invoice_btns .cs-invoice_btn:last-child {
  border-radius: 0 5px 5px 0;
}

.cs-invoice_btn {
  display: inline-flex;
  align-items: center;
  border: none;
  font-weight: 600;
  padding: 8px 20px;
  cursor: pointer;
}

.cs-invoice_btn svg {
  width: 24px;
  margin-right: 5px;
}

.cs-invoice_btn.cs-color1 {
  color: #111111;
  background: rgba(42, 209, 157, 0.15);
}

.cs-invoice_btn.cs-color1:hover {
  background-color: rgba(42, 209, 157, 0.3);
}

.cs-invoice_btn.cs-color2 {
  color: #fff;
  background: #19AAF8;
}

.cs-invoice_btn.cs-color2:hover {
  background-color:  #19AAF8;
}

.cs-table_responsive {
  overflow-x: auto;
}

.cs-table_responsive > table {
  min-width: 600px;
}

.cs-50_col > * {
  width: 50%;
  flex: none;
}

.cs-bar_list {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.cs-bar_list::before {
  content: '';
  height: 75%;
  width: 2px;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #eaeaea;
}

.cs-bar_list li {
  position: relative;
  padding-left: 25px;
}

.cs-bar_list li:before {
  content: '';
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #eaeaea;
  position: absolute;
  left: 0;
  top: 6px;
}

.cs-bar_list li:not(:last-child) {
  margin-bottom: 10px;
}

.cs-table.cs-style1.cs-type1 {
  padding: 10px 30px;
}

.cs-table.cs-style1.cs-type1 tr:first-child td {
  border-top: none;
}

.cs-table.cs-style1.cs-type1 tr td:first-child {
  padding-left: 0;
}

.cs-table.cs-style1.cs-type1 tr td:last-child {
  padding-right: 0;
}

.cs-table.cs-style1.cs-type2 > * {
  padding: 0 10px;
}

.cs-table.cs-style1.cs-type2 .cs-table_title {
  padding: 20px 0 0 15px;
  margin-bottom: -5px;
}

.cs-table.cs-style2 td {
  border: none;
}

.cs-table.cs-style2 td,
.cs-table.cs-style2 th {
  padding: 12px 15px;
  line-height: 1.55em;
}

.cs-table.cs-style2 tr:not(:first-child) {
  border-top: 1px dashed #eaeaea;
}

.cs-list.cs-style1 {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cs-list.cs-style1 li {
  display: flex;
}

.cs-list.cs-style1 li:not(:last-child) {
  border-bottom: 1px dashed #eaeaea;
}

.cs-list.cs-style1 li > * {
  flex: none;
  width: 50%;
  padding: 7px 0px;
}

.cs-list.cs-style2 {
  list-style: none;
  margin: 0 0 30px 0;
  padding: 12px 0;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}

.cs-list.cs-style2 li {
  display: flex;
}

.cs-list.cs-style2 li > * {
  flex: 1 1;
  padding: 5px 25px;
}

.cs-heading.cs-style1 {
  line-height: 1.5em;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0;
}

.cs-no_border {
  border: none !important;
}

.cs-grid_row {
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
  list-style: none;
  padding: 0;
}

.cs-col_2 {
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.cs-col_3 {
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
}

.cs-col_4 {
  -ms-grid-columns: (1fr)[4];
      grid-template-columns: repeat(4, 1fr);
}

.cs-border_less td {
  border-color: transparent;
}

.cs-special_item {
  position: relative;
}

.cs-special_item:after {
  content: '';
  height: 52px;
  width: 1px;
  background-color: #eaeaea;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.cs-table.cs-style1 .cs-table.cs-style1 tr:not(:first-child) td {
  border-color: #eaeaea;
}

.cs-table.cs-style1 .cs-table.cs-style2 td {
  padding: 12px 0px;
}

.cs-ticket_wrap {
  display: flex;
}

.cs-ticket_left {
  flex: 1 1;
}

.cs-ticket_right {
  flex: none;
  width: 215px;
}

.cs-box.cs-style1 {
  border: 2px solid #eaeaea;
  border-radius: 5px;
  padding: 20px 10px;
  min-width: 150px;
}

.cs-box.cs-style1.cs-type1 {
  padding: 12px 10px 10px;
}

.cs-max_w_150 {
  max-width: 150px;
}

.cs-left_auto {
  margin-left: auto;
}

.cs-title_1 {
  display: inline-block;
  border-bottom: 1px solid #eaeaea;
  min-width: 60%;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.cs-box2_wrap {
  display: -ms-grid;
  display: grid;
  grid-gap: 30px;
  list-style: none;
  padding: 0;
  -ms-grid-columns: (1fr)[2];
      grid-template-columns: repeat(2, 1fr);
}

.cs-box.cs-style2 {
  border: 1px solid #eaeaea;
  padding: 25px 30px;
  border-radius: 5px;
}

.cs-box.cs-style2 .cs-table.cs-style2 td {
  padding: 12px 0;
}

#mainEmpty{
  display: table;
  width: 100%;
  height: 75vh;
  text-align: center;
  background-color:'#F0F2F5';
}
.fofempty{
  display: table-cell;
  vertical-align: middle;
}

/* #mobileIns span:last-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 30px;
} */

#root {
  height: 100%;
}

.p-0 {
  padding: 0;
}

.p-15 {
  padding: 15px;
}

.p-x-15 {
  padding: 0 15px;
}

.p-y-15 {
  padding: 15px 0px;
}

.p-x-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.logo {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

/***Login Wrapper start****/
#root {
  height: 100%;
}

.login-form-wrapper {
  height: 100%;
  width: 100%;
  display: inline-flex;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  justify-content: center;
  background: url(/static/media/login_bg.d65c8778.png) !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  position: relative !important;
  background-size: cover !important;
}
.login-form-wrapper .logo-wrapper {
  z-index: 9;
  text-align: center;
}
.login-form-wrapper .logo-wrapper .loginLogo {
  width: 200px;
  border-radius: 50%;
  z-index: 9;
  margin: 0 auto;
  margin-bottom: 57px;
}
.login-form-wrapper .logo-wrapper .page-title {
  font-size: 24px;
  font-weight: bold;
  background: #fff;
  padding: 5px 20px;
  z-index: 11111;
  border-radius: 10px;
  margin-bottom: 0 !important;
  text-transform: uppercase;
  color: #c1692f;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-top: -8px;
}
.login-form-wrapper .login-form-inner-right {
  background: #fff;
  z-index: 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 60px;
  padding-right: 60px;
  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
}
.login-form-wrapper .login-form-inner-right .login-form {
  width: 100%;
}
.login-form-wrapper .login-form-inner-right .login-form .custom-form {
  border: none;
  border-bottom: 1px solid #C1C1C1;
  border-radius: 0 !important;
}
.login-form-wrapper .login-form-inner-right .login-form .ant-input-affix-wrapper-focused {
  border-color: transparent;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none !important;
  border-bottom: 1px solid #00CA88;
}
.login-form-wrapper .login-form-inner-right .login-form input:-internal-autofill-selected {
  background: transparent !important;
}
.login-form-wrapper .login-form-inner-right .login-form .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00CA88;
  border-color: #00CA88;
}
.login-form-wrapper .login-form-inner-right .login-form .submitButton {
  background: #00CA88;
  text-transform: uppercase;
  width: 166px;
  border-radius: 50px;
  border-color: #00CA88;
  height: auto !important;
}
.login-form-wrapper .login-form-inner-right .login-form .submitButton span {
  font-weight: 600;
  letter-spacing: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.login-form-wrapper .login-form-inner-right .login-form .submitButton:hover {
  background-color: #00B489 !important;
  color: #00CA88 !important;
}
.login-form-wrapper .login-form-inner-right .login-form .social-wrapper {
  border-top: 1px solid #13BA7F;
  padding-top: 10px;
  margin-top: 40px;
}
.login-form-wrapper .login-form-inner-right .login-form .social-wrapper a {
  color: #00CA88;
  font-size: 20px;
}
.login-form-wrapper .login-form-inner-right .page-sub-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  color: #13B680;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 10px;
}
.login-form-wrapper .login-form-inner-right .login-title {
  margin-bottom: 30px;
}
.login-form-wrapper .login-form-inner-right .login-title h1 {
  font-size: 32px;
  color: #13B680;
  font-weight: 700;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.login-form-wrapper .login-form-inner-right .login-title p {
  font-size: 12px;
  color: #000000;
}
.login-form-wrapper .login-form-inner-right hr {
  margin-top: 15px;
  margin-bottom: 15px;
}
.login-form-wrapper .login-form-inner-right input[type=text] {
  height: 40px;
}
.login-form-wrapper .login-form-inner-right .mt-30 {
  margin-top: 30px;
}
.login-form-wrapper .login-form-inner-right .otp input:focus-visible {
  background-color: transparent !important;
  outline: #00B218 !important;
  border: 1px solid #00B218 !important;
}
.login-form-wrapper .ant-input-affix-wrapper {
  border-radius: 15px;
}
.login-form-wrapper .ant-input-affix-wrapper input {
  margin-left: 10px;
}
.login-form-wrapper .ant-input-affix-wrapper:focus {
  border-color: #00CA88 !important;
  box-shadow: none;
}
.login-form-wrapper .ant-input-affix-wrapper:hover {
  border-color: #00CA88 !important;
}
.login-form-wrapper .signupPage-wrapper .ant-col.ant-form-item-control .ant-input {
  border-color: #00CA88 !important;
}
.login-form-wrapper .ant-alert {
  position: fixed;
  right: 20px;
  top: 0;
}

.signupPage-wrapper-full {
  background: url(/static/media/Community-tree.5e84ee5f.jpg), linear-gradient(33deg, rgb(14, 176, 75) 43%, rgb(14, 176, 75) 100%);
  background-repeat: no-repeat !important;
  background-position: top center !important;
  position: relative !important;
  background-size: cover !important;
  background-attachment: fixed;
}

.ant-upload .ant-btn {
  height: 40px !important;
  border: 1px solid #00B489 !important;
}
.ant-upload .ant-btn:hover, .ant-upload .ant-btn:focus {
  border: 1px solid #00B489 !important;
  color: #00B489 !important;
}

.ant-steps {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.ant-steps .ant-steps-item-active .ant-steps-item-title::after {
  background-color: #00CA88 !important;
}
.ant-steps .ant-steps-item-icon {
  height: 40px !important;
  width: 40px !important;
  line-height: 40px !important;
  font-weight: bold;
}
.ant-steps .ant-steps-item-title {
  line-height: 40px;
  font-size: 18px !important;
}
.ant-steps .ant-steps-item-title::after {
  position: absolute;
  top: 20px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 2px;
  background: #00CA88;
  content: "";
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgb(10, 88, 71);
  font-weight: bold;
}

.overlay-9 {
  z-index: 9;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.d-none {
  display: none !important;
}

.container {
  width: 100%;
}

.col-md-7 {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-5 {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-md-6 {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mb-20 {
  margin-bottom: 20px;
}

.d-block {
  display: block !important;
}

@media screen and (min-width: 992px) {
  .login-form-wrapper .container {
    width: 946px;
    max-width: 946px;
  }
  .login-form-inner {
    position: relative;
  }
  .d-md-block {
    display: block !important;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .login-form {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}
.signUp-wrapper {
  width: 100%;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.signUp-wrapper span {
  color: #00CA88;
}
.signUp-wrapper a.signup {
  font-weight: 600;
  color: #00CA88;
}
.signUp-wrapper a.signup:hover {
  color: #1caa7b !important;
}
.signUp-wrapper a.forgetPassword {
  color: #00CA88;
  font-weight: 500;
}
.signUp-wrapper a.forgetPassword:hover {
  color: #1caa7b !important;
}

.loginIcons span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: nowrap;
}

.loginIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.loginIcons .anticon {
  margin-right: 5px;
}
.loginIcons div {
  color: #4A4A4A;
  margin-right: 5px;
}
.loginIcons div a {
  color: #4A4A4A;
}
.loginIcons div a:hover {
  color: #42BC30 !important;
}

.text-center {
  text-align: center;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.bg-white {
  background-color: #fff;
}

.box-shadow {
  box-shadow: 1px 8px 11px 2px rgba(0, 0, 0, 0.2784313725);
}

.border-radius-35 {
  border-radius: 40px !important;
}

@media screen and (max-width: 990px) {
  .login-form-inner-right {
    border-radius: 40px !important;
  }
}
/***Login Wrapper end****/
.logo {
  padding: 5px 20px;
  font-size: 20px;
  color: #1A1A2F;
  font-weight: 500;
  border-right: 2px solid #ffffff;
  height: 64px;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: #ffffff;
}

.side-menu-wrapper {
  flex: 0 0 240px !important;
  max-width: 240px !important;
  min-width: 240px !important;
  width: 240px !important;
}
.side-menu-wrapper.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}
.side-menu-wrapper.ant-layout-sider-collapsed .logo {
  width: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.side-menu-wrapper.ant-layout-sider-collapsed .ant-layout-sider-trigger {
  width: 80px !important;
}
.side-menu-wrapper .ant-menu-item {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.side-menu-wrapper .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #ffffff;
}

.ant-layout-sider .ant-layout-sider-trigger {
  width: 240px !important;
}

.ant-row {
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.ant-row.ant-form-item {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.login-form-wrapper .ant-col.ant-form-item-control .ant-input {
  border-color: transparent !important;
}

.ant-col {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.ant-col .ant-form-item-has-error .ant-picker {
  border-color: #ff4d4f !important;
}
.ant-col.ant-form-item-control {
  padding: 0 !important;
}
.ant-col.ant-form-item-control .ant-input {
  border: 1px solid #2243CE !important;
}
.ant-col.ant-form-item-control .ant-select-selector {
  border: 1px solid #2243CE !important;
}
.ant-col.ant-form-item-control .ant-picker {
  border: 1px solid #2243CE;
}
.ant-col.ant-form-item-control .ant-select-multiple .ant-select-selector {
  min-height: 40px !important;
  height: auto !important;
}
.ant-col.ant-form-item-control .custom-password {
  border: 1px solid #2243CE !important;
  height: 40px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.ant-col.ant-form-item-control .custom-password .ant-input {
  border-color: transparent !important;
  background-color: transparent !important;
}
.ant-col.ant-form-item-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ant-col .ant-picker {
  height: 40px;
}
.ant-col .ant-form-item-label label {
  font-size: 14px;
  font-weight: 500;
}

.ant-layout-header {
  padding: 0 15px !important;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.2);
}
.ant-layout-header h3 {
  color: #1A1A2F !important;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
}

.ant-layout {
  background: transparent;
}

.ant-card {
  box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.15);
}

.box-shadow-none {
  box-shadow: none !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}

.ant-card-head {
  padding: 0 15px;
}

.ant-form-item-control-input-content .ant-input {
  height: 40px;
}

.ant-popover {
  padding-top: 4px;
}
.ant-popover .ant-popover-inner-content {
  padding: 0;
}
.ant-popover .ant-popover-inner-content .ant-card {
  box-shadow: none;
  margin-top: 0 !important;
}

.ant-select .ant-select-selector {
  height: 40px !important;
}

.pull-right {
  float: right;
}

.ant-table-wrapper {
  overflow: auto;
}

@media screen and (max-width: 768px) {
  .custom-form .ant-form-item-label label {
    height: 40px;
    color: #1A1A2F;
  }
}
@media screen and (min-width: 768px) {
  .custom-form .ant-form-item-label {
    background: #2243CE;
    text-align: left;
    padding-left: 10px !important;
    color: #fff;
  }
  .custom-form .ant-form-item-label label {
    height: 40px;
    color: #0a582f;
  }
}
.price-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.price-wrapper .ant-input-number {
  border: 1px solid #2243CE;
  border-right: none;
  width: calc(100% - 35px);
}
.price-wrapper .ant-input-number .ant-input-number-input {
  height: 40px;
}
.price-wrapper .price-title {
  padding: 8px;
  border: 1px solid #2243CE;
  height: 42px;
  width: 35px;
}

.ant-input-number {
  width: 100% !important;
  border: 1px solid #2243CE !important;
}

.ant-form-item-has-error .ant-input-number {
  border: 1px solid #ff4d4f !important;
}

.custom-text-input .ant-form-item-control-input-content {
  height: 40px;
  border: 1px solid #2243CE;
  padding: 5px;
}

.ant-layout-sider-children {
  background-color: white;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffffff !important;
}

.ant-btn.ant-btn-primary {
  color: #fff;
  background: #3668F3;
  border-color: #3668F3;
}
.ant-btn.ant-btn-primary:hover {
  background: #ffffff;
  border-color: #3668F3;
  color: #3668F3;
}
@media screen and (max-width: 767px) {
  .ant-btn {
    margin-top: 0;
  }
}

.ant-form.ant-form-vertical .ant-btn {
  margin-top: 30px;
  height: 42px;
}

.mt-30 {
  margin-top: 30px;
}

.ant-popover-inner {
  padding: 10px !important;
}

.float-right {
  float: right;
}

.mt-0 {
  margin-top: 0 !important;
}

.no-margin-label .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px !important;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.placeholderColorChange::-webkit-input-placeholder {
  color: #767676;
}

.placeholderColorChange:-ms-input-placeholder {
  color: #767676;
}

.placeholderColorChange::-ms-input-placeholder {
  color: #767676;
}

.placeholderColorChange::placeholder {
  color: #767676;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #767676;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #767676;
}

/*****mobile screen******/
@media screen and (max-width: 767px) {
  .m-t-mo-30 {
    margin-top: 30px;
  }
  .ant-form.ant-form-vertical .ant-btn {
    margin-top: 0px;
  }
  .mt-mo-0.mt-30 {
    margin-top: 0px;
  }
}
.ant-form.ant-form-vertical .ant-upload .ant-btn {
  margin-top: 0px !important;
}

.img-responsive {
  max-width: 100%;
}

.testimonial-modal-wrapper .ant-radio {
  position: absolute;
  left: 0;
  top: 0;
}
.testimonial-modal-wrapper .ant-radio-wrapper {
  width: 100%;
  margin-right: 0;
  display: flex;
}
.testimonial-modal-wrapper img {
  width: 100%;
}

.cardTemplateWrapper .ant-radio-button-wrapper {
  height: 100% !important;
}
.cardTemplateWrapper .ant-radio-button-wrapper {
  padding: 10px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.dashboard-box-bg-3 {
  background-image: linear-gradient(218deg, #B954A4 0%, #EB4885 100%) !important;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto;
}

.dashboard-box-bg-4 {
  background: #4A1185 !important;
}

.text-light {
  color: #fff !important;
}
.text-light .ant-card-head-title {
  color: #fff !important;
}

.profile-sort-details-wrapper {
  padding: 20px;
}
.profile-sort-details-wrapper .profile-image {
  text-align: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid #efefef;
  margin: 0 auto;
  padding: 5px;
  display: flex;
  align-items: center;
}
.profile-sort-details-wrapper .profile-image img {
  width: 100%;
  border-radius: 50%;
}
.profile-sort-details-wrapper .user-name {
  margin-top: 15px;
}
.profile-sort-details-wrapper .user-name h4 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 700;
}
.profile-sort-details-wrapper .user-name span {
  font-size: 15px;
  color: #b8b8b8;
}
.profile-sort-details-wrapper ul {
  padding-left: 5px;
  margin-top: 15px;
  margin-bottom: 0px;
}
.profile-sort-details-wrapper ul li {
  border: none !important;
  padding: 10px 5px;
  box-shadow: none;
  list-style-type: none;
  color: #767676;
  border-bottom: 1px solid #767676;
  cursor: pointer;
}
.profile-sort-details-wrapper ul li:hover {
  background: rgba(175, 196, 255, 0.153);
}
.profile-sort-details-wrapper ul li a {
  color: #767676;
}
.profile-sort-details-wrapper ul li svg {
  margin-right: 15px;
}

#topFixedBar {
  top: 0;
  right: 0;
}

.menu-open {
  width: calc(100% - 230px);
}

.menu-close {
  width: calc(100% - 80px);
}

@media screen and (max-width: 991px) {
  .menu-close {
    width: calc(100% - 80px);
  }
  .menu-open {
    width: calc(100% - 230px);
  }
}
.instiute-name {
  white-space: nowrap;
  width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.link-wrapper {
  color: #767676;
  font-size: 15px;
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: 15px;
  display: block;
}

.ant-form-item-control .ant-select-multiple .ant-select-selector {
  min-height: 40px !important;
  height: auto !important;
}

.ant-table-cell .ant-select-selector {
  min-height: 40px !important;
  height: auto !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: auto !important;
}

/***Mobile Design start****/
.mobile-view-wrapper .centerx {
  text-align: center;
}
.mobile-view-wrapper .outer-bodyx {
  width: 220px;
  height: 463px;
  border: 2px solid #a2917f;
  margin: 50px auto;
  border-radius: 30px;
  background-color: #ebebeb;
  padding: 12px;
  position: relative;
  margin-top: 0px;
}
.mobile-view-wrapper .headerx .sensor {
  width: 5px;
  height: 5px;
  margin: 0 auto 5px;
  border-radius: 5px;
  background-color: #585657;
}
.mobile-view-wrapper .front-camera {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #75797a;
  margin-right: 12px;
  margin-left: -20px;
}
.mobile-view-wrapper .speaker {
  display: inline-block;
  width: 60px;
  height: 4px;
  margin-bottom: 2px;
  background-color: #747879;
}
.mobile-view-wrapper .bodyx {
  background: #00ca88;
  width: 100%;
  height: 370px;
  margin-top: 15px;
  border: 1px solid #9F9F9F;
}
.mobile-view-wrapper .mobileMessage {
  border-radius: 4px;
  padding: 15px;
  width: 100%;
  color: white;
  height: 100%;
  overflow: overlay;
}
.mobile-view-wrapper .footerx .home-button {
  width: 36px;
  height: 36px;
  background-color: #ebecee;
  border: 2px solid #a2917f;
  border-radius: 20px;
  margin: 10px auto;
}
.mobile-view-wrapper .right-panel-btn-1 {
  display: inline-block;
  height: 15px;
  border: 1px solid #a2917f;
  position: absolute;
  top: 70px;
  left: -4px;
}
.mobile-view-wrapper .right-panel-btn-2 {
  display: inline-block;
  height: 30px;
  border: 1px solid #a2917f;
  position: absolute;
  top: 105px;
  left: -4px;
}
.mobile-view-wrapper .right-panel-btn-3 {
  display: inline-block;
  height: 30px;
  border: 1px solid #a2917f;
  position: absolute;
  top: 145px;
  left: -4px;
}
.mobile-view-wrapper .left-panel-btn {
  display: inline-block;
  height: 35px;
  border: 1px solid #a2917f;
  position: absolute;
  top: 110px;
  right: -4px;
}

/***Mobile Design end****/

/*# sourceMappingURL=styles.css.map */

